


<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
</style>

<style lang="scss">
body {
  background: #fff !important;
}
</style>


<style lang="scss" scoped>
#header {
  position: relative !important;
}

.btn-search-ubicacion {
  text-decoration: none;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-search-ubicacion:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn__ingresar__ubicacion {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 40px;
  font-weight: 500 !important;
  font-size: 11.5px !important;
  letter-spacing: 1px;
}


.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn-cobertura {
  text-decoration: none;
  padding: 7.5px 12px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-cobertura:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn__ingresar__cobertura {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.avatar__alicia {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 220px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 1em;
  font-weight: 500;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

////////////////////////////////@at-root

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 860px) {
  .container__login {
    // height: 79vh !important;
  }
  .avatar__alicia {
    display: none;
  }
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<style>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 385px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 385px;
}
.gmap_iframe {
  height: 385px !important;
}
</style>


<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Cambio de titularidad
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />

                  <div class="form-group">
                    <CInput
                      type="hidden"
                      placeholder="Longitud"
                      class
                      v-model="markers[0].position.lng"
                    ></CInput>
                  </div>

                  <div class="form-group">
                    <CInput
                      type="hidden"
                      placeholder="Latitud"
                      class
                      v-model="markers[0].position.lat"
                    ></CInput>
                  </div>

                  <div class="form-group">
                    <CInput
                      type="hidden"
                      placeholder="document_type"
                      class
                      v-model="nrodoc"
                    ></CInput>
                  </div>

                  <div class="form-group">
                    <CInput
                      type="hidden"
                      placeholder="document_type"
                      class
                      v-model="typedoc"
                    ></CInput>
                  </div>

                  <div class="form-group">
                    <CInput type="hidden" class v-model="route_name"></CInput>
                  </div>

                  <div class="form-group">
                    <CInput
                      type="hidden"
                      placeholder="positionid"
                      class
                      v-model="positionid"
                    ></CInput>
                  </div>
                  <div class="form-group">
                    <CInput type="hidden" class v-model="route_number"></CInput>
                  </div>
                  <div class="form-group">
                    <CInput
                      type="hidden"
                      class
                      v-model="autocomplete_input"
                    ></CInput>
                  </div>

                  
                  <!-- -->
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input"
                        >Nueva Dirección de Reparto
                        <b-icon
                          id="popover-target-1"
                          icon="info-circle-fill"
                          variant="dark"
                        ></b-icon>
                        <b-popover
                          target="popover-target-1"
                          triggers="hover"
                          placement="right"
                          title="Mover el cursor rojo"
                        >
                          <p>
                            En el mapa, seleccione la ubicacion de su nueva
                            dirección, sea preciso al ubicar su nueva dirección.
                          </p>
                        </b-popover>
                      </label>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="9" sm="12">
                      <GmapAutocomplete
                        class="form-control"
                        :position.sync="markers[0].position"
                        @place_changed="setPlace"
                      >
                      </GmapAutocomplete>
                    </CCol>
                    <CCol md="3" sm="12">
                      <button
                        class="btn-search-ubicacion btn__ingresar__ubicacion"
                        type="button"
                        @click="usePlace"
                      >
                        Comprobar Cobertura
                      </button>
                    </CCol>
                  </CRow>
                  <br />
                  <CRow>
                    <CCol md="12" sm="12">
                      <div class="alert alert-info alert-dismissible">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                        >
                          &times;
                        </button>
                        <strong>Bien!</strong>, luego de
                        <strong>comprobar cobertura</strong>, en el mapa
                        <strong>mueva el cursor rojo</strong> a la ubicación de
                        su nueva dirección, sea preciso al ubicar su nueva
                        dirección del nuevo titular.
                      </div>
                    </CCol>
                  </CRow>
                  <br />
                  <CRow>
                    <div class="mapouter">
                      <div class="gmap_canvas">
                        <gmap-map
                          :center="center"
                          :zoom="15"
                          style="width: 100%; height: 400px"
                        >
                          <gmap-marker
                            @dragend="updateMaker"
                            :key="index"
                            :position="center"
                            :clickable="true"
                            :draggable="true"
                            :icon="markerOptions"
                            @click="center"
                          ></gmap-marker>
                        </gmap-map>
                      </div>
                    </div>
                  </CRow>
                  <br />
                  <CRow>
                    <CCol md="4" sm="12">
                      <label class="label__input">Manzana * </label>

                      <CInput
                        v-model="manzana"
                        v-on:keyup="disabledKeyManzana($event)"
                        @keypress="validateNumberAndLetter($event)"
                        style="color: black !important"
                        id="inputManzana"
                        placeholder="Ejemplo: 'I6, E' "
                        disabled=""
                      >
                      </CInput>
                      <!--//TODO: Fecha de Pago -->
                    </CCol>
                    <CCol md="4" sm="12">
                      <!--//TODO: Monto Pagago-->
                      <label class="label__input">Lote * </label>

                      <CInput
                        v-model="lote"
                        v-on:keyup="disabledKeyManzana($event)"
                        @keypress="validateNumber($event)"
                        style="color: black !important"
                        id="inputLote"
                        placeholder="Ejemplo: '28, 29, 30' "
                        disabled=""
                      >
                      </CInput>
                    </CCol>
                    <CCol md="4" sm="12">
                      <!--//TODO: Monto Pagago-->
                      <label class="label__input">Kilómetro * </label>
                      <CInput
                        v-model="kilometro"
                        v-on:keyup="disabledKey($event)"
                        @keypress="validateNumber($event)"
                        style="color: black !important"
                        id="inputKilometro"
                        placeholder="Ejemplo: '23, 24 '"
                        maxlength="7"
                        pattern="[0-9]+"
                        disabled=""
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6" sm="12">
                      <label class="label__input">Número de Domicilio *</label>
                      <CInput
                        v-model="numero"
                        v-on:keyup="disabledKeyNumero($event)"
                        @keypress="validateNumber($event)"
                        maxlength="4"
                        style="color: black !important"
                        id="inputNumero"
                        placeholder="Ejemplo: '512, 412'"
                        disabled=""
                      >
                      </CInput>
                      <!--//TODO: Fecha de Pago -->
                    </CCol>
                    <CCol md="6" sm="12">
                      <!--//TODO: Monto Pagago-->
                      <label class="label__input"
                        >Número de Departamento
                      </label>
                      <CInput
                        v-model="nroDepartamento"
                        @keypress="validateNumber($event)"
                        maxlength="4"
                        style="color: black !important"
                        id="inputNroDepartamento"
                        placeholder="Ejemplo: '512, 412'"
                        disabled=""
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Referencia * </label>
                      <p
                        v-if="isReferencia"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <CInput
                        v-model="referencia"
                        @keypress="validateNumberAndLetter($event)"
                        style="color: black !important"
                        id="inputReferencia"
                        placeholder="Ejemplo: Av. Jose Carlos Mariategui, Paradero 12"
                        disabled=""
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="4" sm="12">
                      <label class="label__input">Forma de entrega *</label>
                      <p
                        v-if="isFormaEntrega"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="formaEntrega"
                        class="form-control"
                        style="color: black !important"
                        id="inputFormaEntrega"
                        disabled
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="DEBAJO PUERTA PRINCIPAL">
                          Debajo de la puerta principal
                        </option>
                        <option value="DEBAJO DE PUERTA DE COCHERA">
                          Debajo de puerta cochera
                        </option>
                        <option value="BUZON">Buzón</option>
                        <option value="RECEPCION">Recepción</option>
                        <option value="AL VIGILANTE">
                          Recepción a vigilante
                        </option>
                        <option value="POR LAS REJAS">Reja a fondo</option>
                      </select>
                    </CCol>
                    <CCol md="4" sm="12">
                      <label class="label__input"
                        >Denominación de domicilio *
                      </label>
                      <p
                        v-if="isDenominacion"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="denominacion"
                        class="form-control"
                        style="color: black !important"
                        id="inputDenominacion"
                        disabled
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="Casa Familiar">Casa familiar</option>
                        <option value="Casa Playa">Casa de playa</option>
                        <option value="Casa Principal">Casa principal</option>
                        <option value="Oficina">Oficina</option>
                      </select>
                    </CCol>
                    <CCol md="4" sm="12">
                      <label class="label__input"
                        >Información adicional *</label
                      >
                      <p
                        v-if="isInfoAdicional"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="infoAdicional"
                        class="form-control"
                        style="color: black !important"
                        id="inputInfoAdicional"
                        disabled
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="CHALET">Chalet</option>
                        <option value="DPTO.">Departamento</option>
                        <option value="ESCUELA">Escuela</option>
                        <option value="INTERIOR">Interior</option>
                        <option value="OFICINA">Oficina</option>
                        <option value="PISO">Piso</option>
                        <option value="TIENDA">Tienda</option>
                      </select>
                    </CCol>
                  </CRow>
                  <br/>
                  <CRow>
                    <CCol md="6" sm="12">
                      <label class="label__input">Género *</label>
                      <p
                        v-if="isGender"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="gender"
                        class="form-control"
                        style="color: black !important"
                        id="inputGenero"
                        disabled
                        @change="changeTemporal($event)"
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                      </select>
                    </CCol>
                    <CCol md="6" sm="12">
                      <label class="label__input">Correo Electrónico *</label>
                      <p
                        v-if="isEmail"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <CInput
                        type="text"
                        v-model="email"
                        style="color: black !important"
                        id="inputEmail"
                        disabled
                      >
                      </CInput>
                    </CCol>
                  </CRow>

                  <button
                    type="button"
                    id="btn-registrar"
                    class="btn-comercio btn__ingresar"
                    disabled
                    @click="registrarDireccion()"
                  >
                    Enviar
                  </button>
                  <br />
                  <br />
                  <h1
                    v-if="messageSave"
                    class="alert alert-success alert-dismissible fade show"
                  >
                    {{ messageSave }}
                  </h1>
                  <br />
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      <b-modal
        id="modal-lg"
        size="xs"
        hide-footer
        no-close-on-backdrop
        centered
        hide-header-close
        no-close-on-esc
      >
        <center>
          <h2><strong>Cambio de titularidad</strong></h2>
          <p style="font-size: 19px">
            Ingresa tu dirección en el buscador, comprueba la cobertura y
            completa los datos del formulario.​ Luego dale clic a enviar.
          </p>
          <br />
          <b-button
            class="mt-3"
            block
            @click="$bvModal.hide('modal-lg')"
            style="padding: 7px; border-radius: 3px"
            >Listo</b-button
          >
        </center>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import Autocomplete from "vuejs-auto-complete";
import VueGoogleMaps from "vue2-google-maps";
import { v4 as uuidv4 } from "uuid";
const mapMarker = require("../../assets/img/map-marker.png");

export default {
  name: "Incidencias",
  data() {
    return {
      markerOptions: {
        url: mapMarker,
        size: { width: 80, height: 80, f: "px", b: "px" },
        scaledSize: { width: 80, height: 80, f: "px", b: "px" },
      },
      route_name: "",
      route_number: "",
      availableTags: [],
      temporal: "",
      isLoading: false,
      token: null,
      positionid: null,
      nrodoc: null,
      typedoc: null,
      message: "",
      messageSave: "",
      directionSave: {},
      ruta: "",
      code: "",
      document_type: "dni",
      document_number: "74402882",
      autocomplete_input: "",
      manzana: "",
      isManzana: false,
      lote: "",
      isLote: false,
      numero: "",
      isNumero: false,
      kilometro: "",
      isKilometro: false,
      referencia: "",
      isReferencia: false,
      formaEntrega: "",
      isFormaEntrega: false,
      denominacion: "",
      isDenominacion: false,
      infoAdicional: "",
      isInfoAdicional: false,
      email: "",
      isEmail: false,
      gender: "",
      isGender: false,
      nroDepartamento: "",
      coordinatesResponse: {},
      center: { lat: -11.954394, lng: -76.98921 },
      markers: [
        {
          position: { lat: -11.954394, lng: -76.98921 },
        },
      ],
      place: null,
    };
  },
  description: "Autocomplete Example (#164)",

  created() {
    this.$getLocation({})
      .then((coordinates) => {
        this.center = coordinates;
      })
      .catch((error) => alert(error));
  },
  components: {
    Loading,
    Table,
    Autocomplete,
  },
  methods: {
    setPlace(place) {
      let vm = this;
      vm.autocomplete_input = place.formatted_address;
      this.place = place;
      let sessionToken = uuidv4();

      let autocompleteService =
        new VueGoogleMaps.gmapApi.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: this.place,
          sessionToken: sessionToken,
        },
        displaySuggestions
      );
    },
    setDescription(description) {
      this.description = description;
    },
    usePlace() {
      if (this.place) {
        var newPostion = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.center = newPostion;
        this.markers[0].position = newPostion;
        this.place = null;
        this.sendCoordinates();
      }
    },
    disabledKey: function (event) {
      let vm = this;
      if (vm.kilometro.length > 0) {
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputManzana").disabled = true;
        document.getElementById("inputLote").disabled = true;
        document.getElementById("inputNumero").disabled = true;
      } else {
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputManzana").disabled = false;
        document.getElementById("inputLote").disabled = false;
        document.getElementById("inputNumero").disabled = false;
      }
    },
    disabledKeyNumero: function (event) {
      let vm = this;
      if (vm.numero.length > 0) {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputKilometro").disabled = true;
        document.getElementById("inputLote").disabled = true;
        document.getElementById("inputManzana").disabled = true;
      } else {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputKilometro").disabled = false;
        document.getElementById("inputLote").disabled = false;
        document.getElementById("inputManzana").disabled = false;
      }
    },
    disabledKeyManzana: function (event) {
      let vm = this;
      if (vm.manzana.length > 0 || vm.lote.length > 0) {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputKilometro").disabled = true;
        document.getElementById("inputNumero").disabled = true;
      } else {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputKilometro").disabled = false;
        document.getElementById("inputNumero").disabled = false;
      }
    },
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[a-zA-Z0-9 ]+$");
      if (!myRex.test(val)) event.preventDefault();
    },
    updateMaker: function (event) {
      this.markers[0].position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    sendCoordinates() {
      let vm = this;
      this.token = this.$route.params.token;

      let data = {
        latitud: vm.markers[0].position.lat,
        longitud: vm.markers[0].position.lng,
        token: this.token,
      };

      axios
        .post(
          `${process.env.VUE_APP_ROOT_API}/actualizardireccion-cobertura`,
          data
        )
        .then(function (res) {
          vm.isLoading = false;

          if (res.data.data.code == 1) {
            vm.$fire({
              type: "success",
              title: "Llegamos a la nueva dirección",
              text: res.data.data.message,
              confirmButtonColor: "#3085d6",
              showCloseButton: true,
              confirmButtonText: "Aceptar",
            }).then((result) => {
              if (result.value == true) {
                
                vm.route_number = res.data.data.cod_cuadrante;
                vm.route_name = res.data.data.ruta;
                document.getElementById("inputNroDepartamento").disabled = false;
                document.getElementById("inputManzana").disabled = false;
                document.getElementById("inputLote").disabled = false;
                document.getElementById("inputNumero").disabled = false;
                document.getElementById("inputKilometro").disabled = false;
                document.getElementById("inputReferencia").disabled = false;
                document.getElementById("inputFormaEntrega").disabled = false;
                document.getElementById("inputDenominacion").disabled = false;
                document.getElementById("inputGenero").disabled = false;
                document.getElementById("inputEmail").disabled = false;
                document.getElementById("inputInfoAdicional").disabled = false;
                document.getElementById("btn-registrar").disabled = false;
              }
            });
          } else if (res.data.data.code == 2) {
            vm.$fire({
              title: "¡Lo sentimos!",
              html: res.data.data.message,
              type: "error",
              timer: 10000,
            }).then((r) => {
              console.log("dismin", r);
              document.getElementById("inputNroDepartamento").disabled = true;
              document.getElementById("inputManzana").disabled = true;
              document.getElementById("inputLote").disabled = true;
              document.getElementById("inputNumero").disabled = true;
              document.getElementById("inputKilometro").disabled = true;
              document.getElementById("inputReferencia").disabled = true;
              document.getElementById("inputFormaEntrega").disabled = true;
              document.getElementById("inputDenominacion").disabled = true;
              document.getElementById("inputInfoAdicional").disabled = true;
              document.getElementById("inputGenero").disabled = true;
              document.getElementById("inputEmail").disabled = true;
              document.getElementById("btn-registrar").disabled = true;
            });
          } else {
            vm.$fire({
              title: "¡Lo sentimos!",
              html: res.data.data.message,
              type: "error",
              timer: 10000,
            }).then((r) => {
              console.log("dismin", r);
              document.getElementById("inputNroDepartamento").disabled = true;
              document.getElementById("inputManzana").disabled = true;
              document.getElementById("inputLote").disabled = true;
              document.getElementById("inputNumero").disabled = true;
              document.getElementById("inputKilometro").disabled = true;
              document.getElementById("inputReferencia").disabled = true;
              document.getElementById("inputFormaEntrega").disabled = true;
              document.getElementById("inputDenominacion").disabled = true;
              document.getElementById("inputInfoAdicional").disabled = true;
              document.getElementById("btn-registrar").disabled = true;
            });
          }
        })
        .catch((error) => console.log(error));
    },
    registrarDireccion() {
      let vm = this;

      vm.isManzana = false;
      vm.isLote = false;
      vm.isNumero = false;
      vm.isKilometro = false;
      vm.isReferencia = false;
      vm.isFormaEntrega = false;
      vm.isDenominacion = false;
      vm.isInfoAdicional = false;
      vm.isEmail = false;
      vm.isGender = false;

      let flag = false;

      if (vm.referencia == "") {
        vm.isReferencia = true;
      }

      if (vm.formaEntrega == "") {
        vm.isFormaEntrega = true;
      }

      if (vm.denominacion == "") {
        vm.isDenominacion = true;
      }

      if (vm.infoAdicional == "") {
        vm.isInfoAdicional = true;
      }

      if (vm.email == "") {
        vm.isEmail = true;
      } else {
        if (
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test( vm.email)
        ) {
          vm.isEmail = false;
        } else {
          vm.isEmail = true;
        }
      }

      if (vm.gender == "") {
        vm.isGender = true;
      }

      if (
        vm.manzana.length > 0 &&
        vm.manzana.length > 0 &&
        vm.numero == "" &&
        vm.numero == ""
      ) {
        flag = false;
      } else if (
        vm.numero.length > 0 &&
        vm.manzana == "" &&
        vm.lote == "" &&
        vm.kilometro == ""
      ) {
        flag = false;
      } else if (
        vm.kilometro.length > 0 &&
        vm.manzana == "" &&
        vm.lote == "" &&
        vm.numero == ""
      ) {
        flag = false;
      } else {
        flag = true;
      }

      if (vm.isEmail == true) {
        vm.$fire({
          title: "¡Error!",
          text: "El correo ingresado no es correcto",
          type: "error",
          timer: 10000,
        }).then((r) => {
          console.log(r);
        });
      } else if (flag == true) {
        vm.$fire({
          title: "¡Error!",
          text: "Verifique que Manzana/Lote - Numero - Kilometro se haya ingresado",
          type: "error",
          timer: 10000,
        }).then((r) => {
          console.log(r);
        });
      } else {
        this.token = this.$route.params.token;
        this.nrodoc = this.$route.params.nrodoc;
        this.typedoc = this.$route.params.typedoc;
        this.positionid = this.$route.params.positionid;
        this.documentNumber = this.$route.params.docNumber;
        this.documentType = this.$route.params.docType;
        this.phone = this.$route.params.phone;
        this.firstname = this.$route.params.firstname;
        this.lastname = this.$route.params.lastname;

        let data = {
          document_type: this.typedoc,
          document_number: this.nrodoc,
          positionId: this.positionid,
          token: this.token,
          documentNumber: this.documentNumber,
          documentType: this.documentType,
          phone: this.phone,
          firstname: this.firstname,
          lastname: this.lastname,
          route_number: vm.route_number,
          route_name: vm.route_name,
          latitud: vm.markers[0].position.lat,
          longitud: vm.markers[0].position.lng,
          referencia: vm.referencia,
          formaEntrega: vm.formaEntrega,
          denominacion: vm.denominacion,
          infoAdicional: vm.infoAdicional,
          email: vm.email,
          gender: vm.gender,
          manzana: vm.manzana,
          kilometro: vm.kilometro,
          lote: vm.lote,
          numero: vm.numero,
          nroDepartamento: vm.nroDepartamento,
          autocomplete_place: vm.autocomplete_input,
        };

        axios
          .post(
            `${process.env.VUE_APP_ROOT_API}/actualizardireccion-titularidad`,
            data
          )
          .then(function (res) {
            vm.isLoading = false;
            let code = res.data.data.code;

            if (code == 1) {
              vm.$fire({
                title: res.data.data.messageSuccess,
                text: res.data.data.message,
                type: "success",
                timer: 10000,
              }).then((r) => {
                setTimeout(() => {
                  vm.$router.push("/");
                }, 10000);
              });
            } else if (code == 2) {
              vm.$fire({
                title: "¡Lo sentimos!",
                text: res.data.data.message,
                type: "info",
                timer: 5000,
              }).then((r) => {
                setTimeout(() => {
                  vm.$router.push("/");
                }, 5000);
              });
            } else {
              vm.$fire({
                title: "¡campo incompletos o inválidos!",
                html: res.data.data.message,
                type: "error",
                timer: 10000,
              }).then((r) => {
                console.log("dismin", r);
              });
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrio un error en el servidor",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
  },
  mounted() {
    let vm = this;

    vm.$bvModal.show("modal-lg");

    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    $("#pgb-container").hide();
  },
};
</script>