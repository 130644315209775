<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div v-if="flag">
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Cambio dirección a playa
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />

                  <!-- //TODO: Tipo documento Y N° Documento -->
                  <CRow>
                    <CCol class="mb-sm-3" md="6" sm="12">
                      <label class="label__input">Tipo documento</label>
                      <p
                        v-if="isTipoDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo tipo de documento está vacio
                      </p>
                      <select
                        v-model="tipoDocumento"
                        class="form-control"
                        style="color: black !important"
                        @change="changeTipoDocumento($event)"
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="DNI">DNI</option>
                        <option value="RUC">RUC</option>
                        <option value="Carnet de extranjeria">
                          Carnet de extranjeria
                        </option>
                        <option value="Pasaporte">Pasaporte</option>
                        <option value="CDI">CDI</option>
                      </select>
                    </CCol>
                    <!--//TODO: Numero documento-->
                    <CCol md="6" sm="12">
                      <label class="label__input">N° Documento </label>
                      <p
                        v-if="isNumeroDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo Numero documento esta vacio
                      </p>
                      <CInput
                        @keypress="validateNumber($event)"
                        @keyup="validateLength($event)"
                        v-model="numeroDocumento"
                      ></CInput>
                    </CCol>
                  </CRow>
                  <!-- TODO: boton confirmar numero de documneto -->
                  <CRow>
                    <CCol class="mb-3" md="12" sm="12">
                      <button
                        type="button"
                        class="btn-comercio btn__ingresar"
                        @click="verificar($event)"
                      >
                        Verificar
                      </button>
                    </CCol>
                  </CRow>
                  <!--//TODO: Suscripcion Contratada -->
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Suscripción Contratada</label>
                      <p
                        v-if="isSuscripcion"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo suscripción está vacio
                      </p>
                      <select
                        disabled
                        id="inputSuscripcion"
                        @change="consulta($event)"
                        v-model="suscripcion"
                        class="form-control mb-3"
                        style="color: black !important"
                      >
                        <option value="">--Seleccionar--</option>
                        <option
                          v-for="(suscrip, index) in listaSuscripciones"
                          :key="index + 1"
                          :value="index + 1"
                        >
                          {{ index + 1 }}. {{ suscrip.product }}-{{
                            suscrip.id
                          }}
                        </option>
                      </select>
                    </CCol>
                  </CRow>

                  <!-- UBICACION -->
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Distrito</label>
                      <p
                        v-if="isDistribucion"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)Debes seleccionar una ubicación
                      </p>
                      <select
                        disabled
                        class="form-control mb-3"
                        style="color: black !important"
                        name=""
                        id="distrito"
                        @change="selectUbication($event)"
                      >
                        <option value="">--Seleccionar--</option>
                        <option
                          v-for="(item, index) in listaDistribucones"
                          :value="item"
                          :key="index"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </CCol>
                  </CRow>

                  <!-- Playa -->
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Playa</label>
                      <p
                        v-if="isPlaya"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)Debes seleccionar una ubicación
                      </p>
                      <select
                        disabled
                        class="form-control mb-3"
                        style="color: black !important"
                        name=""
                        id="playa"
                        @change="selectPlaya($event)"
                      >
                        <option value="">--Seleccionar--</option>
                        <option
                          v-for="(item, index) in listaPlayas"
                          :value="index"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <p class="label__input">Detalles de dirección</p>
                      <hr />
                    </CCol>
                  </CRow>
                  <!-- INICIO FORMULARIO-->
                  <CRow>
                    <CCol md="6" sm="12">
                      <label class="label__input">Manzana * </label>

                      <CInput
                        v-model="manzana"
                        v-on:keyup="disabledKeyManzana($event)"
                        @keypress="validateNumberAndLetter($event)"
                        style="color: black !important"
                        id="inputManzana"
                        placeholder="Ejemplo: 'I6, E' "
                        disabled
                      >
                      </CInput>
                      <!--//TODO: Fecha de Pago -->
                    </CCol>
                    <CCol md="6" sm="12">
                      <!--//TODO: Monto Pagago-->

                      <label class="label__input">Lote * </label>

                      <CInput
                        v-model="lote"
                        v-on:keyup="disabledKeyManzana($event)"
                        @keypress="validateNumber($event)"
                        style="color: black !important"
                        id="inputLote"
                        placeholder="Ejemplo: '28, 29, 30' "
                        disabled
                      >
                      </CInput>
                    </CCol>
                    <!--  <CCol md="4" sm="12">
                      <label class="label__input">Kilómetro * </label>
                      <CInput
                        v-model="kilometro"
                        v-on:keyup="disabledKey($event)"
                        @keypress="validateNumber($event)"
                        style="color: black !important"
                        id="inputKilometro"
                        placeholder="Ejemplo: '23, 24 '"
                        maxlength="7"
                        pattern="[0-9]+"
                        disabled=""
                      > 
                      </CInput>
                    </CCol>-->
                  </CRow>
                    <CRow>
                    <CCol md="6" sm="12">
                      <label class="label__input">Número de Domicilio *</label>
                      <CInput
                        v-model="numero"
                        v-on:keyup="disabledKeyNumero($event)"
                        @keypress="validateNumber($event)"
                        maxlength="6"
                        style="color: black !important"
                        id="inputNumero"
                        placeholder="Ejemplo: '512, 412'"
                        disabled
                      >
                      </CInput>
                      <!--//TODO: Fecha de Pago -->
                    </CCol>
                    <CCol md="6" sm="12">
                      <!--//TODO: Monto Pagago-->
                      <label class="label__input"
                        >Número de Departamento
                      </label>
                      <CInput
                        v-model="nroDepartamento"
                        @keypress="validateNumber($event)"
                        maxlength="6"
                        style="color: black !important"
                        id="inputNroDepartamento"
                        placeholder="Ejemplo: '512, 412'"
                        disabled
                      >
                      </CInput>
                    </CCol>
                  </CRow>


                  
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Referencia * </label>
                      <p
                        v-if="isRef"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <CInput
                        v-model="ref"
                        style="color: black !important"
                        id="ref"
                        placeholder="Ejemplo: Av. Jose Carlos Mariategui, Paradero 12"
                        disabled
                      />
                      
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="4" sm="12">
                      <label class="label__input">Forma de entrega *</label>
                      <p
                        v-if="isFormaEnt"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="formaEnt"
                        class="form-control"
                        style="color: black !important"
                        id="formaEntrega"
                        disabled
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="AL VIGILANTE">
                          Recepción a vigilante
                        </option>
                      </select>
                    </CCol>
                    <CCol md="4" sm="12">
                      <label class="label__input"
                        >Denominación de domicilio *
                      </label>
                      <p
                        v-if="isDenom"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="denom"
                        class="form-control"
                        style="color: black !important"
                        id="denom"
                        disabled
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="Casa Familiar">Casa familiar</option>
                        <option value="Casa Playa">Casa de playa</option>
                        <option value="Casa Principal">Casa principal</option>
                        <option value="Oficina">Oficina</option>
                      </select>
                    </CCol>
                    <CCol md="4" sm="12">
                      <label class="label__input"
                        >Información adicional *</label
                      >
                      <p
                        v-if="isInfoAdi"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="infoAdi"
                        class="form-control"
                        style="color: black !important"
                        id="infoAdi"
                        disabled
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="CHALET">Chalet</option>
                        <option value="DPTO.">Departamento</option>
                        <option value="ESCUELA">Escuela</option>
                        <option value="INTERIOR">Interior</option>
                        <option value="OFICINA">Oficina</option>
                        <option value="PISO">Piso</option>
                        <option value="TIENDA">Tienda</option>
                      </select>
                    </CCol>
                  </CRow>
               
                  <CRow>
                    <CCol md="4" sm="12">
                      <label class="label__input"
                        >Cambio temporal
                        <b-icon
                          id="popover-target-9"
                          icon="info-circle-fill"
                          variant="dark"
                        ></b-icon>
                        <b-popover
                          target="popover-target-9"
                          triggers="hover"
                          placement="right"
                        >
                          Recuerde que todo cambio, <br />
                          comienza despues de dos dias, <br />
                          a la fecha seleccionada.
                        </b-popover>
                      </label>
                      <p
                        v-if="isCambioTempo"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <select
                        v-model="cambioTempo"
                        class="form-control"
                        style="color: black !important"
                        id="cambioTempo"
                        disabled
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="SI">SI</option>
                      </select>
                    </CCol>
                    <CCol md="4" sm="12">
                      <label class="label__input"
                        >Fecha de inicio del cambio *
                      </label>
                      <p
                        v-if="isFechaIni"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <CInput
                        type="date"
                        v-model="fechaIni"
                        style="color: black !important"
                        id="fechaIni"
                        disabled
                      >
                      </CInput>
                    </CCol>
                    <CCol md="4" sm="12">
                      <label class="label__input"
                        >Fecha final del cambio *</label
                      >
                      <p
                        v-if="isFechaFin"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <CInput
                        type="date"
                        v-model="fechaFin"
                        style="color: black !important"
                        id="fechaFin"
                        disabled
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="12" sm="12">
                      <button
                        type="button"
                        id="registrar"
                        class="btn-comercio btn__ingresar"
                        disabled
                        @click="registrarFreshdesk()"
                      >
                        Enviar
                      </button>
                    </CCol>
                  </CRow>









                  <!-- FIN FORMULARIO -->
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
  <div v-else class="page-not-available">
    <p>Oooops Página no disponible</p>
    <p>Recuerda que los formularios de atención de <b>"Cambio de dirección"</b>,<b>"Incidencia de reparto"</b> y <b>"Otras consultas y solicitudes"</b> estan disponibles en el siguiente horario: <b>7:00 pm a 6:59 am</b></p>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ProgressBars from "../base/ProgressBars.vue";
import dataPlaya from "../data/dataPlaya.json";

export default {
  name: "PageCambioDireccionPlaya",
  data() {
    return {
      /* FIXME: valores nesesarios */
      
      isLoading: false,
      fullPage: true,

      isTipoDocumento: false,
      isNumeroDocumento: false,
      isSuscripcion: false,
      isDistribucion: false,
      isPlaya: false,
      isManzana: false,
      isLote: false,
      isNumero: false,
      isRef: false,
      isFormaEnt: false,
      isDenom: false,
      isInfoAdi: false,
      isCambioTempo: false,
      isFechaIni: false,
      isFechaFin: false,
      tipoDocumento: "",
      numeroDocumento: "",
      suscripcion: "",
      manzana: "",
      lote: "",
      numero: "",
      nroDepartamento: "",
      ref: "",
      formaEnt: "",
      denom: "",
      infoAdi: "",
      cambioTempo: "",
      fechaIni: "",
      fechaFin: "",
      distribucion: "",
      playaSeleccionada: "",
      canilla: "",
      codigo: "",
      km: "",
      repartidorCasa: "",
      ruta: "",
      telefono: "",
      nombrePlaya : "", 

      product : "",

      listaSuscripciones: [],
      listaDistribucones: [],
      listaPlayas: [],
      id: "",

      flag: false,
    };
  },

  components: {
    Loading,
    Table,
    DatePicker,
    ProgressBars,
  },
  methods: {
    /* TODO: Validaciones */

    /* TODO:validar tipo documento new */
    changeTipoDocumentoNew(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumentoNew = id;
      vm.numeroDocumentoNew = "";
    },
    validateNumberNew(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero */
    validateLengthNew(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumentoNew == "DNI") len = 8;
      else if (vm.tipoDocumentoNew == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumentoNew = event.target.value.substring(0, len);
      }
    },
    changeTipoDocumento(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumento = id;
      vm.numeroDocumento = "";
    },
    /* TODO:validar numero */
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    disabledKey: function (event) {
      let vm = this;
      if (vm.kilometro.length > 0) {
        document.getElementById("inputManzana").disabled = true;
        document.getElementById("inputLote").disabled = true;
        document.getElementById("inputNumero").disabled = true;
      } else {
        document.getElementById("inputManzana").disabled = false;
        document.getElementById("inputLote").disabled = false;
        document.getElementById("inputNumero").disabled = false;
      }
    },
    disabledKeyManzana: function (event) {
      let vm = this;
      if (vm.manzana.length > 0 || vm.lote.length > 0) {
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputNumero").disabled = true;
      } else {
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputNumero").disabled = false;
      }
    },
    disabledKeyNumero: function (event) {
      let vm = this;
      if (vm.numero.length > 0) {
        document.getElementById("inputLote").disabled = true;
        document.getElementById("inputManzana").disabled = true;
      } else {
        document.getElementById("inputLote").disabled = false;
        document.getElementById("inputManzana").disabled = false;
      }
    },
    changeTemporal(event) {
      let vm = this;
      let val = event.target.value;
      if (val == "SI") {
        document.getElementById("inputFechaFinal").disabled = false;
      } else {
        vm.fechaFinal = "";
        document.getElementById("inputFechaFinal").disabled = true;
      }
    },
    /* TODO:validar tamaño del numero */
    validateLength(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumento == "DNI") len = 8;
      else if (vm.tipoDocumento == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumento = event.target.value.substring(0, len);
      }
    },
    validateNumberAndPoint(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9.]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9a-zA-z]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    async verificar() {
      let vm = this;
      if (vm.tipoDocumento == "" && vm.numeroDocumento == "") {
        vm.isTipoDocumento = true;
        vm.isNumeroDocumento = true;
      }

      let data = {
        document_number: vm.numeroDocumento,
        document_type: vm.tipoDocumento,
        channel: 7,
        optionQuery: 1,
        queryType: 1,
        optionProcess: 1,
        token: "",
      };

      axios
        .post(`${process.env.VUE_APP_ROOT_API}/listSuscripChatbot`, data)
        .then(function (res) {
          console.log(res);
          vm.isLoading = false;
          if (res.data.data.code == 1) {
            vm.$fire({
              title: "¡Listo!",
              text: "Sus datos fueron verificados con éxito",
              type: "success",
              timer: 4000,
            }).then((r) => {
              vm.listaSuscripciones = res.data.data.landing;
              vm.isTipoDocumento = false;
              vm.isNumeroDocumento = false;

              document.getElementById("inputSuscripcion").disabled = false;
            });
          } else {
            vm.$fire({
              title: "¡Lo sentimos!",
              text: "Usted no cuenta con suscripciones registradas",
              type: "error",
              timer: 10000,
            }).then((r) => {
              console.log("dismin", r);

              document.getElementById("inputSuscripcion").disabled = true;
            });
          }
        })
        .catch((error) => console.log(error));
    },
    async consulta() {
      let vm = this;

      let data1 = {
        position_id: String(vm.suscripcion),
        consulta: "1",
        tipo_documento: vm.tipoDocumento,
        nro_documento: vm.numeroDocumento,
        platformOrigin: "Chatbot",
        queryType: "1",
      };
      await axios
        .post(`${process.env.VUE_APP_ROOT_API}/consultaPaqueteChatbot`, data1)
        .then((res) => {
          vm.isLoading = false;
          vm.id = res.data.data.landing.id;
          vm.product = res.data.data.landing.product;
          /* Cunado todo es correcto */
          for (let i = 0; i < dataPlaya.length; i++) {
            vm.listaDistribucones.push(dataPlaya[i].distribucion);
          }

          /* Fin */
          document.getElementById("distrito").disabled = false;
          document.getElementById("playa").disabled = false;
        })
        .catch((error) => {
          vm.isLoading = false;
          vm.id = res.data.data.landing.id;
          vm.product = res.data.data.landing.product;
          document.getElementById("distrito").disabled = true;
          document.getElementById("playa").disabled = true;
        });
    },
    /* NUEVAS FUNCIONES */
    async selectUbication(event) {
      let vm = this;
      vm.distribucion = event.target.value;

      /* Cunado todo es correcto */
      let res = await dataPlaya.filter(
        (item) => item.distribucion == vm.distribucion
      );

      vm.listaPlayas = res[0].playas;
    },
    async selectPlaya(event) {
      let vm = this;
      vm.playaSeleccionada = event.target.value;
      let data1 = vm.listaPlayas[vm.playaSeleccionada];
        vm.nombrePlaya = data1.name;
        vm.canilla = data1.canilla,
        vm.codigo = data1.codigo,
        vm.km = data1.km,
        vm.repartidorCasa = data1.repartidor,
        vm.ruta = data1.ruta,
        vm.telefono = data1.telefono;

        document.getElementById("inputNroDepartamento").disabled = false;
        document.getElementById("inputManzana").disabled = false;
        document.getElementById("inputLote").disabled = false;
        document.getElementById("inputNumero").disabled = false;
        document.getElementById("ref").disabled = false;
        document.getElementById("formaEntrega").disabled = false;
        document.getElementById("denom").disabled = false;
         document.getElementById("infoAdi").disabled = false;
         document.getElementById("cambioTempo").disabled = false;
         document.getElementById("fechaIni").disabled = false;
        document.getElementById("fechaFin").disabled = false;
          document.getElementById("registrar").disabled = false; 
       
    },

    async registrarFreshdesk() {

      let vm = this;

      vm.isDistribucion = false;
      vm.isPlaya = false;
      vm.isManzana = false;
      vm.isLote = false;
      vm.isNumero = false;
      vm.isRef = false;
      vm.isFormaEnt = false;
      vm.isDenom = false;
      vm.isCambioTempo = false;
      vm.isInfoAdi = false;
      vm.isFechaIni = false;
      vm.isFechaFin = false;

      
    if (vm.ref == "") {
        vm.isRef = true;
      }

      if (vm.formaEnt == "") {
        vm.isFormaEnt = true;
      }

      if (vm.denom == "") {
        vm.isDenom = true;
      }

      if (vm.cambioTempo == "") {
        vm.isCambioTempo = true;
      }

      if (vm.infoAdi == "") {
        vm.isInfoAdi = true;
      }
     if (vm.fechaIni == "") {
        vm.isFechaIni = true;
      }

      if (
        vm.manzana.length > 0 &&
        vm.lote.length > 0 
      ) {
        vm.numero = "-"
      } else if (
        vm.numero.length > 0 &&
        vm.nroDepartamento.length > 0
      ) {
        vm.manzana = "-"
        vm.lote = "-"
      }else {
        vm.manzana = "-"
        vm.lote = "-"
        vm.numero = "-"
      }

      
         if (
        vm.isFechaIni == true ||
        vm.isInfoAdi == true ||
        vm.isRef == true ||
        vm.isFormaEnt == true ||
        vm.isDenom == true
      ) {
        vm.$fire({
          title: "¡Error!",
          text: "Verifique que se haya completado los campos de: manzana,lote,numero y/o kilometro",
          type: "error",
        }).then((r) => {
          console.log(r);
        });
        } else {
          let data = {
            tipoDocumento: vm.tipoDocumento,
            numeroDocumento: vm.numeroDocumento,
            suscripcion: vm.suscripcion,
            distribucion: vm.distribucion,
            manzana: vm.manzana,
            lote: vm.lote,
            numero: vm.numero,
            ref: vm.ref,
            nroDepartamento : vm.nroDepartamento,
            formaEntrega: vm.formaEnt,
            denominacion: vm.denom,
            cambioTemporal: vm.cambioTempo,
            infoAdicional: vm.infoAdi,
            fechaInicial: vm.fechaIni,
            fechaFinal: vm.fechaFin,
            kilometro: vm.km,
            repartidorCasa: vm.repartidorCasa,
            ruta: vm.ruta,
            telefono: vm.telefono,
            canilla: vm.canilla,
            codigo: vm.codigo,
            nombrePlaya: vm.nombrePlaya,
            product: vm.product,
            id: vm.id,
          };

          //console.log("Data =>", data);

           await axios
            .post(`${process.env.VUE_APP_ROOT_API}/freshdesk/cambio-playa`, data)
            .then(function (res) {
              vm.isLoading = false;
              if (res.data.data.code == 1) {
                vm.$fire({
                  title: `Sus datos fueron actualizados correctamente`,
                  html: `${res.data.data.message} <br/><br/> Gracias por escribirnos.`,
                  type: "success",
                  timer: 6000,
                }).then((r) => {});

                vm.tipoDocumento= "";
                vm.numeroDocumento= "";
                vm.suscripcion= "";
                vm.manzana= "";
                vm.lote= "";
                vm.numero= "";
                vm.nroDepartamento= "";
                vm.ref= "";
                vm.formaEnt= "";
                vm.denom= "";
                vm.infoAdi= "";
                vm.cambioTempo= "";
                vm.fechaIni= "";
                vm.fechaFin= "";
                vm.distribucion= "";
                vm.playaSeleccionada= "";
                vm.canilla= "";
                vm.codigo= "";
                vm.km= "";
                vm.repartidorCasa= "";
                vm.ruta= "";
                vm.telefono= "";
                vm.nombrePlaya = ""; 
                vm.product = "";
                vm.id = "";
              } else {
                vm.$fire({
                  title: "Seguridad",
                  html: res.data.data.message,
                  type: "info",
                  timer: 40000,
                }).then((r) => {
                  console.log("dismin", r);
                });
              } 
            })
            .catch((error) => {
              console.log(error);
              vm.isLoading = false;
              vm.$fire({
                title: "Error",
                text: "Ocurrio un error en el servidor",
                type: "error",
                timer: 6000,
              }).then((r) => {});
            });
        
        
        }
      
    },

     redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },

  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();

    const date = new Date();
    const hour = date.getHours();

    if ((hour >= 0 && hour < 7) || hour >= 19) {
      vm.flag = true;
    }
  },
};
</script>